import styled from "styled-components"

import * as CommonStyle from "../../common/style/index"

export const Dialog = styled(CommonStyle.Dialog)`
  padding: 8px;
`

export const Content = styled(CommonStyle.DialogContent)`
  border: 1px solid #e3a20f;
`

export const Message = styled(CommonStyle.Message)`
  padding: 8px;
  padding-top: 16px;
`

export const GoldButton = styled(CommonStyle.GoldButton)`
  width: auto;
`
