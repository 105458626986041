import React from 'react'
import { Logger } from '../../../common/uxp/logger'
import { Icons } from '../../arcmask/icons/index'
import { FormattedMessage } from '../../common/style/formattedMessage'
import * as CommonStyle from '../../common/style/index'
import * as GlobalStyle from '../../common/style/panelStyle'
import { useGlobalState } from '../../../common/uxp/state'
import * as Style from './style'
import { useUxp } from '../../../common/uxp/shared/useUxp'

const GetProCompact = () => {
  const uxp = useUxp()
  return (
    <CommonStyle.Center>
      <Style.GoldButton
        onClick={() => {
          uxp.shell.openExternal('https://arcpanel.averin.photo')
        }}
      >
        <FormattedMessage id='paywalls.getpro' />
      </Style.GoldButton>
    </CommonStyle.Center>
  )
}

export const ComingSoon = () => {
  const { state, dispatch } = useGlobalState()
  if (!state.arcretouch.comingSoon) return <></>
  return (
    <Style.Dialog>
      <Style.Content>
        <CommonStyle.Close
          onClick={() => {
            dispatch({
              type: 'comingSoon',
              payload: false
            })
          }}
        >
          <GlobalStyle.FilledIcon>
            <Icons.Close slot='icon' />
          </GlobalStyle.FilledIcon>
        </CommonStyle.Close>
        <CommonStyle.Headline>
          <FormattedMessage id='arcpanel.comingsoon' />
        </CommonStyle.Headline>
        <Style.Message>
          <FormattedMessage id='paywalls.comingsoon.message' />
        </Style.Message>
      </Style.Content>
    </Style.Dialog>
  )
}

export const ARCRetouchPaywall = () => {
  const l = Logger(false, '[ARCRetouchPaywall]')
  const { state, dispatch } = useGlobalState()
  if (state.user.pro) {
    return <></>
  }

  return (
    <Style.Dialog>
      <Style.Content>
        <CommonStyle.Headline>
          <FormattedMessage id='paywalls.arcretouch.headline' />
        </CommonStyle.Headline>
        <Style.Message>
          <FormattedMessage id='paywalls.arcretouch.message' />
        </Style.Message>
        <GetProCompact />
      </Style.Content>
    </Style.Dialog>
  )
}
