import React, { useEffect } from 'react'

import ARCRetouch_Tools from './arcretouch_tools'
import ARCRetouch_FS from './arcretouch_frequencyseparation'
import ARCRetouch_Orton from './arcretouch_orton'
import ARCRetouch_DB from './arcretouch_dodgeburn'
import { ThemeProvider } from 'styled-components'
import { ARCRetouchPaywall, ComingSoon } from './paywall/index.js'
import { PanelGrid } from '../common/panelGrid.js'
import { useGlobalState } from '../../common/uxp/state.js'
import { ARCRetouchLogicProvider } from '../../common/uxp/shared/arcretouch_logic'

const Panel = () => {
  return (
    <PanelGrid>
      <ARCRetouch_Tools />
      <ARCRetouch_FS />
      <ARCRetouch_Orton />
      <ARCRetouch_DB />
    </PanelGrid>
  )
}

export default () => {
  const { state, dispatch } = useGlobalState()
  return (
    <ARCRetouchLogicProvider>
      <ARCRetouchPaywall />
      <ComingSoon />
      {state.user.pro && <Panel />}
    </ARCRetouchLogicProvider>
  )
}
